<template>
	<div class="courseCarousel">
		<div class="courseCarousel__header">
			<CommonTitleCourse :value="category.title">
				<template v-slot:controls>
					<CommonControlsCarousel
						:disableNext="isEnd || isLocked"
						:disablePrev="isBeginning || isLocked"
						@prev="prev()"
						@next="next()"
					/>
				</template>
			</CommonTitleCourse>
		</div>

		<div class="courseCarousel__container">
			<div class="courseCarousel__container--box">
				<Swiper
					:ref="swiperName"
					:options="swiperOptions"
				>
					<!-- Loading -->
					<template v-if="loading">
						<SwiperSlide
							v-for="i in 6"
							:key="i"
						>
							<CommonSkeletonsCover />
						</SwiperSlide>
					</template>

					<!-- Carousel View -->
					<template v-else>
						<template>
							<SwiperSlide
								v-for="item in coursesByCategory(category.id)"
								:key="item.id"
							>
								<div>
									<CommonCardsCourse
										:data="item"
										@show="$router.push({ path: 'curso/' + item.id })"
										@edit="$router.push({ path: 'config_curso/' + item.id })"
									/>
									<CommonBoxCourse :data="item" />
								</div>
							</SwiperSlide>
						</template>
					</template>
				</Swiper>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	components: {
		CommonTitleCourse: require("@/components/common/title/Course.vue").default,
		CommonBoxCourse: require("@/components/common/box/Course.vue").default,
		CommonCardsCourse: require("@/components/common/cards/Course.vue").default,
		CommonSkeletonsCover: require("@/components/common/skeletons/Cover.vue").default,
		CommonControlsCarousel: require("@/components/common/carousel/ControlsCarousel.vue").default,
	},
	props: {
		category: {
			type: Object,
			required: true,
		},
		courses: {
			type: Array,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			uniqueId: "",
			isLocked: false,
			isBeginning: true,
			isEnd: false,
			swiperOptions: {
				direction: "horizontal",
				loop: false,
				height: 60,
				spaceBetween: 24,
				slidesPerView: "auto",
				centeredSlides: false,
				resizeReInit: true,
				watchOverflow: true,
				on: {
					lock: () => {
						this.isLocked = true;
					},
					unlock: () => {
						this.isLocked = false;
					},
					progress: progress => {
						if (progress === 1) {
							this.isBeginning = false;
							this.isEnd = true;
						} else if (progress <= 0) {
							this.isBeginning = true;
							this.isEnd = false;
						} else {
							this.isBeginning = false;
							this.isEnd = false;
						}
					},
					reachEnd: () => {
						this.$emit("reachEnd");
					},
				},
			},
		};
	},
	computed: {
		...mapGetters({
			getCoursesList: "home/getCoursesList",
			getIsMobile: "layout/getIsMobile",
			getHideDisabledCourses: "config/getHideDisabledCourses",
		}),
		swiperName() {
			return `${this.uniqueId + this.category.title + this.category.id}`;
		},
	},
	created() {
		this.uniqueId = this.createUniqueId();
	},
	methods: {
		coursesByCategory(categoryId) {
			return this.courses.filter(item => item.category_id === categoryId);
		},
		createUniqueId() {
			const timestamp = new Date().getTime();
			const randomNumber = Math.floor(Math.random() * 10000);
			const uniqueId = `${timestamp}_${randomNumber}`;
			return uniqueId;
		},
		prev() {
			this.$refs[this.swiperName].$swiper.slidePrev();
		},
		next() {
			this.$refs[this.swiperName].$swiper.slideNext();
		},
	},
};
</script>

<style lang="scss" scoped>
.courseCarousel {
	padding: 0;

	&__container {
		position: relative;
		max-width: 100%;
		overflow: hidden;

		&--box {
			position: relative;
		}
	}

	.swiper-slide {
		position: relative;
		max-width: 264px;
		min-height: 92px;
		border-radius: var(--default-radius);
		overflow: hidden;

		@media screen and (max-width: 820px) {
			max-width: 264px;
		}

		@media screen and (max-width: 768px) {
			max-width: 264px;
		}

		@media screen and (max-width: 480px) {
			max-width: calc(100% / 2);
		}

		@media screen and (max-width: 360px) {
			::v-deep .commonCardsCover__box {
				max-width: 264px;
			}
		}

		@media screen and (max-width: 310px) {
			max-width: 264px;
		}
	}
}
</style>
